import React from "react";
import Search from "../components/Home/Search";
import { Helmet } from 'react-helmet-async';

const Courses = () => {
  return (
    <div className="min_height">
      <Helmet>
        <>
          <title>Certification, Skill Development & Professional Courses | Edulley</title>
          <meta name="robots" content="index, follow" />
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <meta name="revisit-after" content="1 days" />
          <meta name="author" content="Edulley" />
          <meta name="description" content="Discover Edulley's diverse selection of certification and skill development courses. Elevate your professional journey and achieve success with us." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://edulley.com/courses" />
          <meta property="og:title" content="Certification, Skill Development & Professional Courses | Edulley" />
          <meta property="og:description" content="Discover Edulley's diverse selection of certification and skill development courses. Elevate your professional journey and achieve success with us." />
          <link rel="canonical" href="https://www.edulley.com/courses" />
        </>
      </Helmet>
      <Search />
    </div>
  );
};

export default Courses;