import React from "react";

const ContactUs = () => {
  return (
    <div className="container py-5">
  Contact us
    </div>
  );
};

export default ContactUs;
